import { useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";

import { supportChainIds, chains, ChainInfo } from "../utils/chains";
import { switchNetwork, addToNetwork, connectWallet } from "../utils/wallet";
import { useAppSelector } from "../hooks";
import { selectUser } from "../store/slices/user";
import { selectChain } from "../store/slices/chain";

const fetchChainData = (function () {
  let chainDataCatch: ChainInfo[] = [];
  return async function () {
    if (chainDataCatch.length > 0) return chainDataCatch;
    const response = await fetch("https://chainid.network/chains.json");
    chainDataCatch = await response.json();
    return chainDataCatch;
  };
})();

async function changeNetwork(address: string | null, chainInfo: ChainInfo) {
  try {
    if (address === null) {
      await connectWallet();
    }
    await switchNetwork(chainInfo.chainId);
  } catch (err: unknown) {
    // This error code indicates that the chain has not been added to MetaMask.
    /**
     * In TypeScript, when you use a try-catch statement,
     * the variable type in the catch clause must be either any or unknown.
     * This is because in JavaScript, any value can be thrown,
     * including primitive types (such as strings or numbers),
     * so TypeScript cannot determine the exact type of the caught error.
     * ---- by chatGPT 4
     */
    const hasCode = typeof err === "object" && err !== null && "code" in err;
    // hasCode type check generated by chatGPT 4
    if (hasCode) {
      if (err.code === 4902) {
        addToNetwork(address, chainInfo);
      }
      addToNetwork(address, chainInfo);
    }
  }
}

function ChainItem({
  chain,
  onClick,
}: {
  chain: ChainInfo;
  onClick: Function;
}) {
  const { t } = useTranslation();
  return (
    <div className="card m-3">
      <div className="card-body">
        <h5 className="card-title">{chain.name}</h5>
        <p className="card-text"></p>
        <button
          className="btn btn-primary"
          onClick={() => onClick && onClick()}
        >
          {t("wrongNetwork.change")}
        </button>
      </div>
    </div>
  );
}

export function WrongNetwork() {
  const { t } = useTranslation();
  const [fullChainList, setFullChainList] = useState<ChainInfo[]>([]);
  const [loading, setLoading] = useState(true);

  const user = useAppSelector(selectUser);
  const chain = useAppSelector(selectChain);

  useEffect(() => {
    fetchChainData()
      .then(setFullChainList)
      .then(() => setLoading(false));
  }, []);

  const selectedNetwork = fullChainList.find(
    (c: ChainInfo) => c.chainId === chain.id
  );

  return (
    <div className="container text-center my-5 py-5">
      {loading ? (
        <h6 className="display-6 my-5">&nbsp;</h6>
      ) : (
        <h6 className="display-6 my-5">
          <Trans components={[<span className="text-primary" />]}>
            {t("wrongNetwork.notSupport", {
              networkName: `{${
                selectedNetwork?.name ?? t("wrongNetwork.selected")
              }}`,
            })}
          </Trans>
        </h6>
      )}
      <p className="lead">{t("wrongNetwork.pleaseSelect")}</p>
      <div className="d-flex flex-row justify-content-center">
        {supportChainIds
          .filter((id) => !chains[id].local)
          .map((id) => (
            <ChainItem
              key={id}
              chain={chains[id].info}
              onClick={() => {
                changeNetwork(
                  user.authed ? user.address : null,
                  chains[id].info
                );
              }}
            />
          ))}
      </div>
    </div>
  );
}
